<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Técnicos'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="handleBtnOpenDialogCreateTechnician()">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table technicians -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="technicians"
        :totalItems="$store.state.technicians.totalCountTechnicians"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getTechnicians"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/tecnicos/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create technician -->
    <DialogBox :model="dialogCreateTechnician">
      <template slot="toolbar">
        <span>Crear técnico</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateTechnician" v-model="formCreateTechnicianValid">
          <v-select
            v-model="technician.role"
            :items="['TECHNICIAN', 'SUPERTECHNICIAN']"
            :rules="[(v) => !!v || 'Campo obligatorio']"
            label="Rol"
          />
          <div v-if="technician.role">
            <v-text-field v-model="technician.first_name" label="Nombre" :rules="formRules.textRules" />
            <v-text-field v-model="technician.last_name" label="Apellidos" :rules="formRules.textRules" />
            <v-text-field v-model="technician.email" label="Email" :rules="formRules.emailRules" />
            <v-text-field v-model="technician.identity_document" label="Documento de identidad" :rules="formRules.textRules" />
            <v-text-field v-model="technician.phone" label="Teléfono" :rules="formRules.phoneRules" />
            <CountryAutocomplete :countryId="technician.country" :editable="true" @selected="handleCountrySelection($event)" />
            <v-autocomplete
              v-if="!_.isEmpty(technician.country)"
              v-model="technician.city"
              :items="cities"
              item-text="name"
              item-value="id"
              label="Ciudad"
              :rules="formRules.textRules"
            />
            <v-text-field v-if="!_.isEmpty(technician.city)" v-model="technician.zone" label="Zona" :rules="formRules.textRules" />
            <v-select
              v-if="technician.role === 'TECHNICIAN' && !_.isEmpty(technician.country)"
              v-model="technician.technician.office_id"
              :items="offices"
              item-text="name"
              item-value="id"
              label="Oficina"
              :rules="[(v) => !!v || 'Campo obligatorio']"
            />
            <v-autocomplete
              v-if="technician.role === 'SUPERTECHNICIAN'"
              v-model="technician.technician.partner_id"
              :items="partners.filter((e) => e.country.id === technician.country)"
              item-text="name"
              item-value="id"
              label="Partner"
              :rules="[(v) => !!v || 'Campo obligatorio']"
            />
            <v-text-field v-model="technician.address.name" label="Dirección" :rules="formRules.textRules" />
            <v-text-field
              v-model="technician.password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              label="Contraseña"
              :rules="formRules.passwordRequiredRules"
              @click:append="showPassword = !showPassword"
            />
          </div>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateTechnician', 'formCreateTechnician')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  name: "Technicians",
  components: {
    Banner,
    DataTable,
    DialogBox,
    CountryAutocomplete
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN"]
      },

      // Technicians datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Documento de identidad", value: "identity_document", sortable: false },
        { text: "Teléfono", value: "phone", sortable: false },
        { text: "Nombre", value: "first_name", sortable: false },
        { text: "Apellidos", value: "last_name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Rol", value: "role", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Dialogs
      dialogCreateTechnician: false,
      formCreateTechnicianValid: false,

      // Fields of technician
      technician: {
        role: "",
        technician: {
          office_id: "",
          partner_id: ""
        },
        first_name: "",
        last_name: "",
        email: "",
        identity_document: "",
        phone: "",
        country: "",
        city: "",
        zone: "",
        address: {
          coordinates: {
            lat: 0,
            lon: 0
          },
          name: ""
        },
        password: ""
      },
      showPassword: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      dbUser: (state) => state.user.dbUser,
      technicians: (state) => state.technicians.technicians,
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices,
      cities: (state) => state.locations.cities
    }),
    _() {
      return _;
    }
  },
  async created() {
    if (this.user.role === "SUPERTECHNICIAN") {
      await this.getTechnicians({
        size: 10,
        sort: { created_at: "DESC" },
        filter: { "partner.id": this.dbUser.technician.partner_id }
      });
    } else await this.getTechnicians({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getTechnicians: "technicians/getTechnicians"
    }),

    // Create technician
    async create() {
      if (this.$refs.formCreateTechnician.validate()) {
        let technicianFormattedData = { ...this.technician };

        technicianFormattedData.role === "TECHNICIAN"
          ? delete technicianFormattedData.technician.partner_id
          : delete technicianFormattedData.technician.office_id;

        console.log(technicianFormattedData);

        await this.$store.dispatch("technicians/createTechnician", technicianFormattedData);
        await this.getTechnicians({ size: 10, sort: { created_at: "DESC" } });
        this.closeDialog("dialogCreateTechnician", "formCreateTechnician");
      }
    },

    async handlePrevIcon(elasticData) {
      await this.getTechnicians(elasticData);
    },

    async handleNextIcon(elasticData) {
      this.getTechnicians(elasticData);
    },

    async handleSearch(elasticData) {
      await this.getTechnicians(elasticData);
    },

    async handleBtnOpenDialogCreateTechnician() {
      await this.$store.dispatch("partners/getPartners");
      this.dialogCreateTechnician = true;
    },

    async handleCountrySelection(countryId) {
      await this.$store.dispatch("locations/getCities", { filter: { country: countryId } });
      if (this.technician.role === "TECHNICIAN") await this.$store.dispatch("offices/getOffices", { filter: { "country.id": countryId } });
      this.technician.city = null;
      this.technician.country = countryId;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.technician = this.$options.data().technician;
    }
  }
};
</script>
